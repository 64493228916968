import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

const sseurl = process.env.REACT_APP_API_URL + 'web/guest_chat/sse';

let chatLog = [];

function useChat(limit = 20, setPreloadMessage, token) {
    const [isLoading, setIsLoading] = useState(false);
    // const [chatLog, setChatLog] = useState([]);

    useEffect(() => {
        const sse = new EventSource(sseurl + `?limit=${limit}&token=${token}`);

        const interval = setInterval(() => {
            setIsLoading(true);
        }, 3000);

        sse.onmessage = (e) => {
            const newMessage = JSON.parse(e.data);
            const sortedResponse = newMessage.reverse().map((obj) => ({...obj, message: orderBy(obj.message, ['created_date'], ['asc'])}));
            // const newMapMessage = newMessage.reverse().map((val) => ({ ...val, message: val.message.reverse() }));

            if (!isEqual(chatLog, sortedResponse)) {
                setPreloadMessage(prevState => prevState.filter((val) => val.replyMessage));
                chatLog = sortedResponse;
                // setChatLog(newMessage.reverse().map((val) => ({ ...val, message: val.message.reverse() })));
            }

            setIsLoading(false);
        };  
        
        sse.onerror = () => {
            sse.close();
            setIsLoading(false);
        };

        return () => {
            sse.close();
            clearInterval(interval);
        };
    }, []);

    return { chatLog, isLoading };
}

export default useChat;