import { API_URL } from "~/config/api";

export async function getListCart(data) {
    try {
        const response = await API_URL.post('web/booking/get_cart', data);

        return response; 
    } catch (err) {
        return false;
    }
}

export async function getDetailExperience(data) {
    try {
        const response = await API_URL.post('web/experience/detail', data);

        return response; 
    } catch (err) {
        return false;
    }
}

export async function addToCart(data) {
    try {
        const response = await API_URL.post('web/booking/add_to_cart', data);

        return response; 
    } catch (err) {
        return false;
    }
}

export async function submitBooking() {
    try {
        const response = await API_URL.post('web/booking/submit');

        return response; 
    } catch (err) {
        return false;
    }
}