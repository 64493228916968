/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import WhiteLogo from '~/assets/image/WhiteLogo';
import ColorLogo from '~/assets/image/ColorLogo';
import { navMenu } from '~/config/constant';
import propTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { SmallCartIcon, CloseIcon, MenuIcon, ProfileUserIcon } from '~/assets/icons';
import { ROUTES } from '~/config/routes/Routes';
import { Link } from 'react-router-dom';
import { CounterContext } from '~/config/providers/ContextProviders';
import DropdownComponent from './Dropdown';
import Cookies from 'universal-cookie';
import ChatIcon from '~/assets/icons/ChatIcon';
import Modal from './Modal';
import ChatRoom from './ChatRoom/ChatRoom';
import { getListCart } from '~/services/cart';

const Header = ({scrollOpacity='',  colorLogo=false,  triggerCart=false}) => {
    const navigate = useNavigate();
    const { state, handleLogout } = React.useContext(CounterContext);
    const { isLogged, credential } = state;

    const {pathname} = useLocation();
    const [toggleMenuOpen, setToggleMenuOpen] = useState(false);
    const [modalVisibility, setModalVisibility] = useState(false);
    const [StartChat, setStartChat] = useState(false);
    const [heightLogo, setHeightLogo] = useState('48');
    let logos = !colorLogo ? <WhiteLogo height={heightLogo} color={toggleMenuOpen ? 'rgb(43 44 50)' : scrollOpacity > 0.5 ? 'rgb(43 44 50)' : 'rgb(255 255 255)'} /> 
        : <ColorLogo height={heightLogo} />;
  
    let userNameColor = colorLogo ? '#2B2C32': scrollOpacity > 0.5 ? '#2B2C32' : '#FFFFFF';
    let menuColor = colorLogo ? '#2B2C32' : scrollOpacity > 0.5 ? '#2B2C32' : '#FFFFFF';
    let iconColor = colorLogo ? '#D6AD60' : scrollOpacity > 0.5 ? '#D6AD60' : '#787D8F';
    let cartIcon = colorLogo ? '#D6AD60' : scrollOpacity > 0.5 ? '#D6AD60' : '#FFFFFF';
    let bgProfile = colorLogo ? 'bg-white' : scrollOpacity >= 1 ? 'bg-transparent' : 'bg-white';

    // let chevronColor = colorLogo ? '#787D8F' : scrollOpacity >= 0.5 ? '#787D8F' : '#FFFFFF';
    // let totalCart = 0;

    const [totalCart, setTotalCart] = useState(0);

    useEffect(() => {
        async function listCart() {
            if(isLogged) {
                const response = await getListCart();

                if(response && response.data.data) {
                    const data = response.data.data?.transaction_booking_service || [];
                    
                    setTotalCart(data.length);
                }
            }
        }

        listCart();
    }, [triggerCart]);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 1280) { 
                setHeightLogo('48');
            } else {
                setHeightLogo('60');
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [heightLogo]);

    function navigationTo(route, query = '', data = '') {
        setToggleMenuOpen(false);
        navigate({
            pathname: route,
            search: query,
        },
        { state: data });
    }

    function toggleMenu() {
        setToggleMenuOpen(!toggleMenuOpen);
    }

    const onLogout = (option) => {
        if (option === 'My Booking') {
            navigationTo(ROUTES.MyBook);
        } else if (option === 'Logout') {
            handleLogout();
    
            const cookies = new Cookies();
            
            cookies.remove('credential');
    
            navigationTo(ROUTES.Homepage);
        }
    };

    const renderModalBox = () => {
        return (
            <div className='flex flex-col items-center p-[20px]'>
                <ChatIcon color='#D6AD60' width={80} height={80} />

                <h3 className='__text-title-1 __ff-neuton tracing-wide mb-[12px] mt-[32px]'>Need Help About Something?</h3>
                <p className='__text-body-1 __ff-lato text-colors-gray200 text-center'>Click the button below to start chatting with our dedicated customer service.</p>
            
                <div className='flex mt-[32px] w-[100%]'>
                    <button onClick={() => setStartChat(true)} className='flex-1 py-3 bg-colors-blue400 mdText __fw-700 text-white'>Start Chat</button>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            {!toggleMenuOpen ? (
                <div style={{background: !colorLogo && scrollOpacity <0.5 ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.4) -39.58%, rgba(0, 0, 0, 0) 100%)' : ''}}>
                    <div className={`container mx-auto flex items-center py-[14px] sm:py-4 ${isLogged ? '' : 'justify-between'}`}>
                        <div className={`max-sm:px-4 max-sm:self-center max-sm:w-full flex items-center xl:justify-between ${isLogged ? 'xl:flex-row-reverse justify-between sm:w-full' : 'xl:flex-row'} xl:items-center`}>
                            <button className='absolute sm:hidden top-0 left-0 mt-4 ml-6 py-2' onClick={toggleMenu}>
                                <MenuIcon color={menuColor} />
                            </button>
                            
                            {isLogged && (
                                <div className='max-xl:absolute top-0 right-0 mt-4 sm:mt-5 xl:mt-0'>
                                    <div className='flex flex-row items-center gap-x-5 lg:gap-x-10 xl:gap-x-[52px] mr-3 sm:mr-10'>
                                        <div className='flex flex-row gap-[24px] py-2'>
                                            <div className='cursor-pointer' onClick={() => setModalVisibility(true)}>
                                                <ChatIcon color={cartIcon} />
                                            </div>
                                            
                                            <Link to={ROUTES.Cart} className='relative'>
                                                {/* <div className={`flex justify-center items-center rounded-full p-1 ${bgProfile}`}> */}
                                                <SmallCartIcon color={cartIcon} />

                                                {/* {triggerCart && (
                                                    <div className='absolute top-[3px] right-[-5px] w-[10px] h-[10px] rounded-full bg-colors-red400' />
                                                )} */}

                                                {parseInt(totalCart) > 0 && (
                                                    <div className={`${totalCart < 1 ? 'hidden' : ''} flex items-center justify-center absolute top-[-6px] -right-2 w-4 h-4 rounded-full bg-[#EA3D3D]`}>
                                                        <p className='text-[10px] font-bold leading-4 text-white px-1'>{totalCart}</p>
                                                    </div>
                                                )}
                                            </Link>
                                        </div>
                                        <DropdownComponent
                                            bgProfile={bgProfile}
                                            placeholder={credential?.username.split('/')[0]} 
                                            listOption={['My Booking', 'Logout']}
                                            labelColor={userNameColor}
                                            icon={<ProfileUserIcon size='20' color={iconColor} />} 
                                            setSelectedOption={onLogout} />
                                    </div>
                                </div>
                            )}

                            <div className='max-sm:mx-auto z-10 xl:hidden' onClick={() => navigationTo(ROUTES.Homepage)}>
                                {logos}
                            </div>
                            
                            <div className='flex items-center max-xl:hidden'>
                                <div className='max-sm:mx-auto z-10' onClick={() => navigationTo(ROUTES.Homepage)}>
                                    {logos}
                                </div>

                                {isLogged && navMenu.map((val, i) => {
                                    if(val.label === 'Experiences') {
                                        return (
                                            <p onClick={() => navigationTo(val.route)}
                                                className={`${pathname === val.route ? 'border-b-colors-gold400 border-b' : ''} cursor-pointer mdText font-medium max-sm:hidden ml-[52px]`} style={{ color: userNameColor }} key={i}>{val.label}</p>
                                        );
                                    }
                                })}
                            </div>
                        </div>
 
                        <Modal
                            modalHeader={false}
                            visibility={modalVisibility}
                            onClose={() => setModalVisibility(false)}
                            renderElement={StartChat ? 
                                <ChatRoom onBack={() => setStartChat(false)} onClose={() => {setModalVisibility(false); setStartChat(false);}} /> 
                                : renderModalBox()} />

                        {!isLogged && (
                            <div className='max-sm:hidden'>
                                <ul className='flex xl:space-x-8 sm:space-x-2 text-white'>
                                    {navMenu.map((val, index) => (
                                        <li onClick={() => navigationTo(val.route)}
                                            key={index}
                                            className={`${pathname === val.route ? 'border-b-colors-gold400 border-b' : ''} cursor-pointer px-2 __text-body-1 ${scrollOpacity > 0.4 ? 'text-colors-gray300' : 'text-white'}`}>
                                            {val.label}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            ) :  (
                <div className={`sm:hidden overflow-y-hidden fixed w-full h-full bg-black bg-opacity-50`} onClick={toggleMenu}/>
            )}

            <div className={`sm:hidden z-50 overflow-hidden w-full h-[100vh] ease-in-out duration-500 fixed left-0 top-0 px-4 ${toggleMenuOpen ? 'translate-x-0' : '-translate-x-full'} bg-white`}>
                <div className='container mx-auto pt-[14px]'>
                    <div className=''>
                        <div className='absolute left-0 top-0 mt-[26px] ml-4 cursor-pointer'
                            onClick={toggleMenu}>
                            <CloseIcon
                                className='h-6 w-6 text-white cursor-pointer'/>
                        </div>
                    </div>
                    <div className='flex flex-col gap-y-[38px] items-center'>
                        <div onClick={() => navigationTo(ROUTES.Homepage)}>
                            {logos}
                        </div>
                        <div className='flex flex-col justify-center items-center gap-y-3'>
                            {navMenu.map((val, i) => {
                                return (
                                    <p
                                        key={i}
                                        onClick={() => navigationTo(val.route)}
                                        className={`smText p-1 font-medium text-color-primaryColor cursor-pointer hover:underline`}>
                                        {val.label}
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Header;

Header.propTypes = {
    scrollOpacity: propTypes.oneOfType([propTypes.number, propTypes.string]),
    colorLogo: propTypes.bool,
    triggerCart: propTypes.bool,
};
